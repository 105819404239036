<style lang="scss">
  @import './ChatBotMessageSender.scss';
</style>

<template>
  <div v-if="show_modal" class="chatbot-modal-container">
    <div class="modal-backdrop" v-on:click="toggleModal(false)"></div>
    <div class="modal">
      <div class="modal-header">
        <h1>Il tuo messaggio a CHATBOT</h1>
        <a v-on:click="toggleModal(false)" class="close-modal-btn">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0595 3.05994L18.9395 0.939941L10.9995 8.87994L3.05945 0.939941L0.939453 3.05994L8.87945 10.9999L0.939453 18.9399L3.05945 21.0599L10.9995 13.1199L18.9395 21.0599L21.0595 18.9399L13.1195 10.9999L21.0595 3.05994Z" fill="#DEDEDE"/>
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <div class="icon-container">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width:200px;" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve">
            <path class="icon-st0" d="M0,500C0,223.9,223.9,0,500,0s500,223.9,500,500s-223.8,500-500,500S0,776.2,0,500"/>
            <g>
              <path d="M404.5,450.1c-11.3-8.2-27.2-5.6-35.4,5.7c-8.2,11.3-5.7,27.1,5.7,35.3c11.3,8.2,27.2,5.6,35.4-5.7
                C418.4,474.1,415.8,458.2,404.5,450.1z"/>
              <path d="M595.9,450.1c-11.3,8.2-13.9,24-5.7,35.3c8.2,11.3,24.1,13.9,35.4,5.7c11.3-8.2,13.9-24,5.7-35.3
                C623.1,444.4,607.3,441.9,595.9,450.1z"/>
              <path d="M381.2,394.8c8,0.4,14.1,1.5,21.4,4.3c0.2,0,1.5,0.6,1.8,0.8c14.9,7.2,13.6,11.9,23.7,4.9c15.7-8.3-0.5-17.2-12.5-23.5
                c-0.9-0.4-1.9-0.8-2.9-1.2c-11-4-20.4-5.1-32.3-4.2c-11.7,1.2-20.4,3.9-30.2,9.5c-4.6,2.9-22.4,17.4-17.4,22.7
                c2.1,0.6,19.8-8.5,25.1-10.2C366,395.6,372.6,394.6,381.2,394.8z"/>
              <path d="M570.8,404.9c10.2,7,8.8,2.3,23.7-4.9c0.3-0.1,1.6-0.7,1.8-0.8c7.3-2.8,13.3-4,21.4-4.3c8.6-0.2,15.2,0.7,23.3,3.1
                c5.2,1.7,23,10.8,25.1,10.2c5.1-5.3-12.8-19.8-17.4-22.7c-9.8-5.6-18.5-8.2-30.2-9.5c-11.9-0.9-21.3,0.2-32.3,4.2
                c-1,0.4-1.9,0.8-2.9,1.2C571.2,387.6,555.1,396.5,570.8,404.9z"/>
              <path d="M503.9,683.5h0.1h0.1c37.5,0,77.8-14.6,77.8-55.4c-26-0.3-52-0.5-77.9-0.5c-25.9,0-51.9,0.2-77.9,0.5
                C426.1,668.9,466.4,683.5,503.9,683.5z"/>
              <path d="M497.1,545c7.6,0,16.6-0.1,23.9-1.3c8.5-1.4,14.8-4.6,14.8-11c0-9.2-9-64.5-9-64.7c-0.3-1.7-1.8-2.8-3.5-2.5
                c-1.7,0.3-2.8,1.8-2.5,3.5c0,0.2,9,54.9,9,63.7c0,2.6-4.1,4.1-9.7,5c-6.8,1.2-15.6,1.2-22.9,1.2c-1.7,0-3.1,1.4-3.1,3.1
                C494.1,543.6,495.4,545,497.1,545z"/>
              <path d="M359.2,910.4c2.5-0.8,4.3-2.9,4.8-5.5c10.8-56.1,11.2-125.6,10.8-154.8c2.1,1.7,4.3,3.3,6.5,4.9
                c37.1,26.9,76.8,40,118.1,39.1c1.4,0,2.8,0,4.2,0c39.7,0,78-13.2,113.9-39.2c2.6-1.9,5.1-3.8,7.6-5.8c-0.4,28.7-0.1,99,10.8,155.7
                c0.5,2.6,2.3,4.7,4.8,5.5c28.6,9.4,57.6,21.2,85.9,35.4c5.2-2.6,10.3-5.3,15.3-8.1c-29.6-15.5-60.6-28.8-92.7-39.6
                c-12.5-69.3-9.7-157.4-9.5-161.3c24.9-22.7,48-51.7,67.5-85.4c3.9-6.8,7.7-13.8,11.4-20.8c2.8,0.7,6.6,1.2,11.9,1.2
                c4.9,0,11.1-0.4,18.9-1.1c19-1.9,35.9-12.7,47.5-30.6c14-21.6,18.1-50.2,10.5-73c-9.9-29.7-32.7-29.2-39.4-28.4
                c-1.2,0.2-2.4,0.4-3.6,0.6c2.2-10.5,4-21.1,5.6-31.7c4.6-24.9,7.8-51.1,8.8-76.4c2.4-60.3-8.9-115.1-25.3-149.1
                c-0.6-1.3-1.2-2.5-1.9-3.8c30.3-57.4,23.6-123.6,16.3-120.1c-55.2,26.4-94.1,21.5-128.3,5.6c-4.9-2.7-9.9-5.3-14.9-7.7
                c-35.4-20-67.3-49.1-109.8-62.1c-66.6-20.3-156.1-21-227.5,61.6c-28.6,33.1-42.1,74.1-34.4,111.7c-2.7,4.8-5.3,9.7-7.7,14.7
                c-16.3,34-27.6,88.8-25.3,149.1c1,25.4,4.2,51.6,8.8,76.5c1.5,10.5,3.4,20.9,5.5,31.3c-0.8-0.2-1.6-0.3-2.4-0.4
                c-6.8-0.8-29.5-1.3-39.4,28.4c-7.6,22.8-3.5,51.4,10.5,73c11.6,17.9,28.5,28.8,47.5,30.6c7.8,0.8,14,1.1,18.9,1.1
                c4.7,0,8.2-0.3,10.9-0.9c3.6,7,7.3,13.9,11.2,20.6c19.8,34.2,43.3,63.6,68.7,86.5c0.3,10.9,2.4,94.1-9.6,160.2
                c-32,10.7-63.1,24.1-92.7,39.6c5.1,2.8,10.2,5.5,15.3,8.1C301.7,931.6,330.6,919.8,359.2,910.4z M741.3,588.6
                c0.8-0.2,1.6-0.8,2.5-1.9c3.3-4.2,7.3-5.9,10.4-5.5c1.1,0.1,2.1,0.6,2.9,1.3c0.7,0.6,1.2,1.5,1.5,2.6c0.6,2.6-0.1,6.4-2.7,11.4
                c-0.8,1.4-0.4,3.3,0.7,4.2c1.1,0.9,2.6,0.6,3.4-0.8c3.6-6.7,4.4-12.4,3.4-16.6c-0.6-2.4-1.7-4.4-3.3-5.8c-1.5-1.3-3.3-2.1-5.3-2.4
                c-3.5-0.4-7.7,0.9-11.4,4.1c0.9-3.9,2.4-9.1,4.2-14.2c3.7-10.5,10.3-20.2,16.8-24.7c2.4-1.7,4.7-2.6,6.7-2.6c1.7,0,3.3,0.9,4.6,2.8
                c2.1,3.2,3.4,9,3.4,18.3c0,1.7,1.1,3.1,2.4,3.1c1.4,0,2.5-1.4,2.5-3.1c0-10.8-1.7-18-4.5-22.2c-2.2-3.4-5.1-5-8.3-5.1
                c-2.9-0.1-6.1,1.2-9.2,3.4c-2.9,2.1-5.8,5-8.6,8.6c3.2-10.7,6.1-21.6,8.6-32.6c2-0.9,4.5-1.7,7.4-2.1c6.1-0.8,21.2-0.2,28.4,21.4
                c6.6,19.9,2.9,45.1-9.4,64.1c-9.9,15.2-24,24.5-39.9,26c-14.5,1.4-21.6,1.2-25.1,0.7l0-0.1c5.3-11,10.3-22.3,14.8-33.8
                C738.8,588.5,739.8,589,741.3,588.6z M251.6,620.4c-15.8-1.6-30-10.8-39.9-26c-12.4-19-16-44.2-9.4-64.1
                c6.2-18.6,18.2-21.6,25.3-21.6c1.2,0,2.2,0.1,3.1,0.2c2.3,0.3,4.4,0.9,6.2,1.6c2.5,11,5.4,21.9,8.6,32.7c-2.6-3.3-5.4-6.1-8.2-8.1
                c-3.1-2.2-6.3-3.4-9.2-3.4c-3.2,0.1-6,1.7-8.3,5.1c-2.8,4.2-4.5,11.4-4.5,22.2c0,1.7,1.1,3.1,2.4,3.1c1.4,0,2.4-1.4,2.4-3.1
                c0-9.3,1.3-15.2,3.4-18.3c1.2-1.9,2.8-2.8,4.6-2.8c2-0.1,4.3,0.9,6.7,2.6c6.4,4.5,13.1,14.2,16.8,24.7c1.8,5.1,3.3,10.3,4.2,14.2
                c-3.8-3.3-7.9-4.6-11.4-4.1c-2,0.3-3.8,1.1-5.3,2.4c-1.6,1.4-2.7,3.3-3.3,5.8c-1,4.2-0.2,9.8,3.4,16.6c0.8,1.4,2.3,1.8,3.4,0.8
                c1.1-0.9,1.4-2.8,0.7-4.2c-2.7-5-3.4-8.9-2.7-11.4c0.3-1.1,0.8-2,1.5-2.6c0.8-0.7,1.8-1.1,2.9-1.3c3.1-0.4,7.1,1.3,10.4,5.5
                c0.9,1.1,1.7,1.7,2.5,1.9c1.4,0.4,2.3-0.1,3-1.2c4.6,11.5,9.5,22.8,14.9,33.8C271.8,621.6,264.7,621.7,251.6,620.4z M305,643.7
                c-11.1-19.2-20.8-39.3-29.1-60l-16.1-52c1.2-59.3,17.4-88.2,33.8-104.7c43.8-49.9,35.7-85.2,33.6-99.1
                c-0.9-5.9-14.2-19.1-26.9-32.8c31.8,21,77.9,31.2,142.5,39.4c81.9,10.4,180.2-0.3,245.1-32.7c3.2-1.6,6.2-3.3,9.2-5
                c-12.2,13.1-24.4,25.4-25.3,31.1c-2.1,13.9-10.2,49.2,33.6,99.1c16.7,16.9,33.3,46.6,33.9,108.3l-10,32.2
                c-9.6,26.5-21.3,52.1-35.3,76.3c-50.6,87.2-118.1,135-190.3,135c-1.3,0-2.6,0-3.9,0l-0.2,0l-0.2,0
                C425.5,780.4,356.5,732.5,305,643.7z"/>
              </g>
            <g>
              <path class="icon-st1" d="M191.3,695.7c-7.9-6.1-17.8-10.8-28.8-8.8c-14.5,2.6-26.2,16.5-25.9,31.4c0.3,15.1,13.1,24.6,27.6,22
                c11.2-2,19.9-8.5,27.6-17.7l-0.5,51.6c-7.7,5.2-25.8,10-34.5,11.6c-38.1,6.7-68.4-18.5-69.1-57.6c-0.7-40.1,29.4-77.6,68.6-84.5
                c11-1.9,22.4-1.9,32.7-0.1L191.3,695.7z"/>
              <path class="icon-st1" d="M207.5,636.9l46.8-8.3l0.8,46.3l29.8-5.3l-0.8-46.3l46.8-8.3l2.4,137.4l-46.8,8.3l-0.9-47.7l-29.8,5.3
                l0.9,47.7l-46.8,8.3L207.5,636.9z"/>
              <path class="icon-st1" d="M394.5,741.7l-51.4,9.1l44-145.6l53.7-9.5l50.1,129l-51.4,9.1l-4.7-16.3l-36.7,6.5L394.5,741.7z M425.7,686.3
                l-6.1-23.2c-1.5-5.6-2.5-11.4-3.5-17.1l-1.8,0.3l-8.1,43.5L425.7,686.3z"/>
              <path class="icon-st1" d="M475.2,589.7l102.1-18l0.8,44.6l-26.8,3.4l1.7,94l-48.6,8.6l-1.7-94l-26.7,6L475.2,589.7z"/>
              <path class="icon-st1" d="M589.7,569.5l56.5-10c25.9-4.6,52.4-2.8,53,28.9c0.2,12-5.4,24.9-17.1,30.8v1.1c14.6,2.7,24,12,24.3,28.4
                c0.5,28.2-24.9,42.4-48.2,46.5l-66.1,11.7L589.7,569.5z M640.1,613.6c7.1-1.3,15.1-3.4,14.9-12.9c-0.2-10.2-9.2-7.9-16.3-6.6
                l-3.4,0.6l0.4,19.7L640.1,613.6z M636.6,666.1l5.5-1c8-1.4,19.5-3.6,19.3-14.9c-0.2-11.5-12.6-9.1-20.4-7.7l-4.8,0.8L636.6,666.1z"
                />
              <path class="icon-st1" d="M864.8,590.3c0.8,44.1-31.9,76.4-72.7,83.5c-40.8,7.2-74.4-13.4-75.2-57.5c-0.8-43.2,32.1-77.1,72.6-84.3
                C830,524.8,864,547.1,864.8,590.3z M765.9,608.4c0.3,15.7,10.8,24.6,25.5,22s24.8-15.2,24.5-30.8c-0.3-14.6-10-25.1-25.5-22.4
                C775,580,765.6,593.9,765.9,608.4z"/>
              <path class="icon-st1" d="M871.5,519.8l102.1-18l0.8,44.6l-26.8,3.4l1.7,94l-48.6,8.6l-1.7-94l-26.7,6L871.5,519.8z"/>
            </g>
          </svg>
        </div>
        <div v-if="canSend">
          <div v-if="sendResult === ''" class="form-container text-center">
            <p class="isa-bot-message">Ciao sono CHATBOT, cosa vorresti chiedermi?</p>
            <textarea class="w-100" rows="6" v-model="message"></textarea>
            <button type="button" v-on:click="sendMessage()" :disabled="!readyToSend">Invia</button>
          </div>        
          <div v-if="sendResult === 'OK'" class="text-center">
            <p class="isa-bot-message">Grazie per averci inviato la tua domanda!<br />CHATBOT ti risponderà entro 48 ore.</p>
            <div class="form-container text-center">
              <button type="button" v-on:click="toggleModal(false)" class="tertiary">Chiudi</button>
            </div>
          </div>
          <div v-if="sendResult === 'KO'" class="form-container text-center">
            <div class="isa-bot-message">
              <strong>Attenzione!</strong>
              <p>
                Si è verificato un errore durante l'invio del tuo messaggio.<br />Ti invitiamo a riprovare più tardi.
              </p>
            </div>          
            <div class="form-container text-center">
              <button type="button" v-on:click="toggleModal(false)" class="tertiary">Chiudi</button>
            </div>
          </div>
        </div>
        <div v-if="!canSend">
          <p class="isa-bot-message">Per inviare il tuo messaggio a CHATBOT devi aver effettuato l'accesso alla community di MyPlay</p>
          <div class="form-container text-center">
            <button type="button" v-on:click="doLogin()" class="primary">Accedi a MyPlay</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'ChatBotMessageSender',
  data: () => ({
    access_token : '',
    show_modal : false,
    message: '',
    sendResult: ''
  }),
  computed: {
    canSend(){
      return this.access_token !== null && this.access_token !== '';
    },
    readyToSend(){
      return this.message.length > 0;
    }
  },
  methods: {
    toggleModal(newState = null){
      if (newState === null){
        newState = !this.show_modal;
      }
      this.show_modal = newState;
      this.sendResult = '';
      this.$emit(newState ? 'modalShown' : 'modalHidden');
    },
    doLogin() {
      this.toggleModal(false);
      this.$emit('doLogin');
    },
    async sendMessage(){
      fetch(`https://api.rtl.it/2.0/my-play/send-message-to-chat-bot/`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${this.access_token}`,
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
            body: this.message
        })
      })
      .then(res => res.json())
      .then(({success = false}) => {
        this.sendResult = (success) ? 'OK' : 'KO';
      })
      .catch(() => {
        this.sendResult = 'KO';
      })
      .finally(() => {
        this.message = '';
      });
    }
  }
}
</script>